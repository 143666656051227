body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 300px; /* Suppose you want minimum width of 1000px */
  width: auto !important; /* Firefox will set width as auto */
  width: 300px; /* As IE6 ignores !important it will set width as 1000px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* These styles make the body full-height */
html,
body {
  height: 100%;
}
/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}
/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}
textarea:focus {
  outline: 0px solid orange; /* oranges! yey */
}
input:focus {
  outline: 0px solid orange; /* oranges! yey */
}
