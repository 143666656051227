html, body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  background-color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgrey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

@font-face {
  font-family: 'Mulish-Bold';
  src: url('./assets/fonts/Mulish/static/Mulish-Bold.ttf');
}
@font-face {
  font-family: 'Mulish-Medium';
  src: url('./assets/fonts/Mulish/static/Mulish-Medium.ttf');
}

@font-face {
  font-family: 'Mulish';
  src: url('./assets/fonts/Mulish/Mulish-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Helvetica_Neue';
  src: url('./assets/fonts/Helvetica-Neue-Font/Helvetica Neue Medium Extended/Helvetica Neue Medium Extended.ttf');
}
body, p, h1, h2, h3, h4, h5 {
  font-family: 'Mulish';
}
* {
  box-sizing: border-box;
}
textarea:focus {
  outline: 0px solid; 
}
input:focus {
  outline: 0px solid;
}
#root {
  display: flex;
  height: 100%;
}
